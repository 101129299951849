// firebase/index
export const firebaseConfig = {
  apiKey: "AIzaSyC_Z5X2sr4seMeCP6Uxd-9kcZk86PRMV7w",
  authDomain: "expo-manantiales.firebaseapp.com",
  projectId: "expo-manantiales",
  storageBucket: "expo-manantiales.appspot.com",
  messagingSenderId: "477290809406",
  appId: "1:477290809406:web:6a1bedb1d5c67bcc45e96f",
  measurementId: "G-35JR3VMEHQ"
}

// firebase/storage
export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/expo-manantiales.appspot.com/o/'